import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClientTable from '../components/ClientTable';
import AddClient from '../components/AddClient';
import SearchBar from '../components/SearchBar';
import { Container, Typography, Box, Snackbar, Alert } from '@mui/material';

const AdminPanel = () => {
    const [clients, setClients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const fetchClients = async () => {
        try {
            const result = await axios.get('https://wlf.onrender.com/clients');
            setClients(result.data);
            setFilteredClients(result.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const addClient = async (client) => {
        try {
            const result = await axios.post('https://wlf.onrender.com/clients', client);
            const updatedClients = [result.data, ...clients];
            setClients(updatedClients);
            setFilteredClients(updatedClients);
        } catch (error) {
            console.error('Error adding client:', error);
        }
    };


    const updateClient = async (id, updatedData) => {
        try {
            const result = await axios.put(`https://wlf.onrender.com/clients/${id}`, updatedData);
            const updatedClients = clients.map(client =>
                client._id === id ? { ...client, ...result.data } : client
            );
            setClients(updatedClients);
            setFilteredClients(updatedClients);
        } catch (error) {
            console.error('Error updating client:', error);
        }
    };

    const deleteClient = async (id) => {
        try {
            await axios.delete(`https://wlf.onrender.com/clients/${id}`);
            const updatedClients = clients.filter(client => client._id !== id);
            setClients(updatedClients);
            setFilteredClients(updatedClients);
        } catch (error) {
            console.error('Error deleting client:', error);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = clients.filter(client =>
            client.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredClients(filtered);
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ mt: 4, mb: 4 }}>Admin Panel</Typography>
            <AddClient addClient={addClient} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <SearchBar searchQuery={searchQuery} setSearchQuery={handleSearch} />
            </Box>
            <ClientTable clients={filteredClients} updateClient={updateClient} deleteClient={deleteClient} />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Clients added successfully!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AdminPanel;
