import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar,Button, Typography, Box, Avatar, IconButton } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Navbar = ({ user }) => {
  const [userData, setUserData] = useState(user || { name: '', surname: '' });
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove token and role from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    // Redirect to login
    navigate('/login');
  };

  useEffect(() => {
    // If no user data is passed down, fetch it from the backend
    if (!userData.name) {
      const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('https://wlf.onrender.com/clients/me', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUserData(response.data.user);
        } catch (error) {
          console.error('Error fetching user data in Navbar:', error);
        }
      };
      fetchUserData();
    }
  }, [userData.name]);

  return (
    <AppBar position="static" sx={{ backgroundColor: '#1E1E1E' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Avatar sx={{ bgcolor: '#d9c67e', mr: 1 }}>
            {userData.name ? userData.name.charAt(0) : <AccountCircle />}
          </Avatar>
        <Typography variant="h6" sx={{ flexGrow: 1, color: '#d9c67e' }}>
      
          <Typography variant="subtitle1" sx={{ color: '#FFFFFF', mr: 2, display: { xs: 'block', sm: 'block' }
 }}>
            {userData.name} {userData.surname}
          </Typography>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
         
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
