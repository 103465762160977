import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Avatar,
  Modal,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  AccountBalanceWallet,
  TrendingFlat,
  CheckCircle,
} from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { addMonths } from 'date-fns';
import axios from 'axios';

const HomePage = () => {
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState({ name: '', surname: '' });
  const [open, setOpen] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawDate, setWithdrawDate] = useState(addMonths(new Date(), 3));
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [totalPending, setTotalPending] = useState(0); // Static pending value
  const [totalWithdrawal, setTotalWithdrawal] = useState(0); // Static withdrawal value
  const [revenueData, setRevenueData] = useState([]);
  const [showCardMessage, setShowCardMessage] = useState(false); // New state for message visibility
  const [fullName, setFullName] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmationClose = () => setShowConfirmation(false);
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for error message
  const [countdown, setCountdown] = useState(24 * 60 * 60); // 24 hours in seconds
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://wlf.onrender.com/clients/me', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data.user);
      setBalance(response.data.balance);
      setStatus(response.data.user.status);

      const withdrawals = response.data.user.withdrawals || [];
      const totalWithdrawalAmount = withdrawals.reduce(
        (sum, withdrawal) => sum + withdrawal.amount,
        0
      );

      setTotalWithdrawal(totalWithdrawalAmount);
      setTotalPending(totalWithdrawalAmount);

      // Prepare revenue data
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const revenueData = daysOfWeek.map((day) => ({
        name: day,
        pending: 0,
        withdraw: 0,
        received: 0,
      }));

      withdrawals.forEach((withdrawal) => {
        const withdrawalDate = new Date(withdrawal.withdrawalDate);
        const dayName = daysOfWeek[withdrawalDate.getDay()];
        const dataIndex = revenueData.findIndex((day) => day.name === dayName);
        if (dataIndex !== -1) {
          revenueData[dataIndex].pending += withdrawal.amount;
          revenueData[dataIndex].withdraw += withdrawal.amount;
        }
      });

      setRevenueData(revenueData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    // Retrieve the login time from localStorage
    const savedLoginTime = localStorage.getItem('loginTime');
    if (savedLoginTime) {
      const timeElapsed = Math.floor((Date.now() - savedLoginTime) / 1000); // Time elapsed in seconds
      const timeLeft = Math.max(24 * 60 * 60 - timeElapsed, 0); // 24 hours minus elapsed time
      setCountdown(timeLeft);
    }
  }, []);

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 0) {
          clearInterval(interval);
          return 0; // Stop the countdown at 0
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  // Call fetchUserData inside useEffect
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleShowCardMessage = () => {
    setShowCardMessage(true); // Show message when button is clicked
  };
  const renderInfoBox = (title, value, description) => (
    <Box
      sx={{
        backgroundColor: '#1E1E1E',
        borderRadius: 3,
        padding: { xs: '20px', md: '30px' },
        textAlign: 'center',
        width: { xs: '80%', md: '90%' },
        maxWidth: '500px',
        marginBottom: 3,
        marginTop: 3,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
        border: '1px solid #333',
        marginLeft: 3,
        marginRight: 3,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant='h6'
          sx={{
            color: '#d9c67e',
            display: 'flex',
            alignItems: 'center',
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
          <AccountBalanceWallet sx={{ mr: 1 }} />
          {title}
        </Typography>
        <Typography
          variant='h4'
          sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
          ${value.toFixed(2)}
        </Typography>
        <Typography variant='body2' sx={{ color: '#d9c67e', mt: 0.5 }}>
          <TrendingFlat fontSize='small' /> 0.0% from last week
        </Typography>
      </Box>
    </Box>
  );
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(countdown);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleWithdrawAmountChange = (e) => {
    const value = e.target.value;
    setWithdrawAmount(value <= balance ? value : balance);
  };
  const handleWithdraw = async () => {
    // Ensure all fields are filled
    if (
      !withdrawAmount ||
      !fullName ||
      !accountNumber ||
      !routingNumber ||
      !withdrawDate
    ) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        'https://wlf.onrender.com/clients/me/withdrawal',
        {
          amount: withdrawAmount,
          fullName,
          accountNumber,
          routingNumber,
          withdrawalDate: withdrawDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update balance after successful withdrawal
      setBalance(response.data.balance);

      setShowConfirmation(true);

      handleClose(); // Close modal after withdrawal
      // Update totalPending and totalWithdrawal immediately
      setTotalPending(
        (prevTotalPending) => prevTotalPending + parseFloat(withdrawAmount)
      );
      setTotalWithdrawal(
        (prevTotalWithdrawal) =>
          prevTotalWithdrawal + parseFloat(withdrawAmount)
      );
    } catch (error) {
      console.error('Error submitting withdrawal:', error);
      setErrorMessage('Failed to submit withdrawal request.');
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }, // Full width on small screens, limited on large screens
        maxWidth: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          lg: '100%',
          xl: '100%',
        }, // Controls maximum width on larger screens
        padding: { xs: 2, md: 4 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#24262b',
        color: '#ffffff',
        //padding: { xs: 2, md: 4 },
        mt: 0,
      }}
    >
      <Box
        component='img'
        src='/logo.png'
        alt='World Liberty Financial Logo'
        sx={{
          width: '100%',
          maxWidth: { xs: '500px', md: '1000px' },
          mb: 2,
          mt: 0, // Reduced top margin
        }}
      />
      {/* User Avatar and Name */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}></Box>

      <Box
        sx={{
          backgroundColor: '#1E1E1E',
          borderRadius: 3,
          padding: '30px',
          textAlign: 'center',
          width: { xs: '80%', lg: '90%' },
          maxWidth: '500px',
          marginBottom: 3,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
          border: '1px solid #333',
        }}
      >
        <Typography
          variant='h5'
          sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            mb: 1,
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
          Total Balance
        </Typography>
        <Typography
          variant='h3'
          sx={{
            fontWeight: 'bold',
            color: '#d9c67e',
            mb: 1,
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
          $
          {balance.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          USD
        </Typography>
        <Typography
          variant='body2'
          color='success.main'
          sx={{
            color: '#d9c67e',
            mb: 2,
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
          +0.0%
        </Typography>
        <Button
          variant='outlined'
          sx={{
            color: '#FFFFFF',
            borderColor: '#FFFFFF',
            '&:hover': {
              borderColor: '#d9c67e',
            },
          }}
        >
          Withdraw Earning
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: '#1E1E1E',
          borderRadius: 3,
          padding: { xs: '20px', md: '30px' },
          textAlign: 'center',
          width: { xs: '80%', md: '90%' },
          maxWidth: '500px',
          marginBottom: 3,
          marginTop: 1,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
          border: '1px solid #333',
          marginLeft: 3,
          marginRight: 3,
          borderColor: status ? 'green' : 'red',
          fontSize: { xs: '0.5rem', md: '1rem' },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant='h4'
            sx={{
              fontWeight: 'bold',
              color: '#ffffff',
              fontSize: { xs: '0.8rem', md: '1rem' },
            }}
          >
            {status
              ? 'ACCOUNT MAINTENANCE FEE COVERED'
              : 'ACCOUNT MAINTENANCE FEE DUE\nPLEASE CONTACT WLF REPRESENTATIVE FOR MORE INFORMATION'}
          </Typography>
        </Box>
      </Box>
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#d9c67e',
          color: '#000000',
          '&:hover': {
            backgroundColor: '#E0C000',
          },
        }}
        onClick={handleOpen}
        disabled={balance <= 0}
      >
        Withdraw to Bank
      </Button>
      {/* New "Get WLF Card" Button */}
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#d9c67e',
          color: '#000000',
          '&:hover': {
            backgroundColor: '#E0C000',
          },
          mt: 2,
        }}
        onClick={handleShowCardMessage} // Show message on click
      >
        Order WLF Card
      </Button>
      {!status && (
        <Box
          sx={{
            backgroundColor: '#1E1E1E',
            borderRadius: 3,
            padding: { xs: '20px', md: '30px' },
            textAlign: 'center',
            marginBottom: 3,
            marginTop: 3,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
            border: '1px solid #333',
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '1rem', md: '1.5rem' },
              marginBottom: 2,
            }}
          >
            Cover account maintenance fee or order WLF card within the due time
            - otherwise your account will be suspended and funds will be lost.
          </Typography>
          <Typography
            variant='h3'
            sx={{
              color: '#d9c67e',
              fontWeight: 'bold',
              fontSize: { xs: '1.5rem', md: '2.5rem' },
            }}
          >
            {`${hours.toString().padStart(2, '0')} Hours 
    ${minutes.toString().padStart(2, '0')} Minutes 
    ${seconds.toString().padStart(2, '0')} Seconds`}
          </Typography>
        </Box>
      )}
      <Modal
        open={showCardMessage}
        onClose={() => {
          handleClose();
          setShowCardMessage(false); // Ensure modal closes completely when hiding confirmation
        }}
        aria-labelledby='wlf-card-modal'
        aria-describedby='wlf-card-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '70%', sm: 400 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant='body1'>WLF Card is available!</Typography>
          <Typography variant='body1'>
            Please contact your WLF Representative if you are interested in
            ordering your card.
          </Typography>
        </Box>
      </Modal>

      {renderInfoBox(
        'Total Withdrawn',
        totalWithdrawal,
        'Total amount withdrawn'
      )}

      {renderInfoBox('Total Pending', totalPending, 'Pending transactions')}
      <Box
        sx={{
          backgroundColor: '#1E1E1E',
          borderRadius: 3,
          padding: { xs: '10px', md: '20px' },
          textAlign: 'center',
          width: { xs: '90%', md: '90%' },

          maxWidth: { xs: '100%', md: '600px' },
          marginBottom: 3,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
        }}
      >
        <Typography
          variant='h5'
          sx={{
            fontWeight: 'bold',
            color: '#FFFFFF',
            mb: 2,
            fontSize: { xs: '1.5rem', md: '2rem' },
          }}
        >
          Revenue Flow
        </Typography>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={revenueData}>
            <CartesianGrid strokeDasharray='3 3' stroke='#333' />
            <XAxis dataKey='name' tick={{ fill: '#ffffff' }} />
            <YAxis tick={{ fill: '#ffffff' }} />
            <Tooltip />
            <Legend />
            <Bar dataKey='pending' stackId='a' fill='#4CAF50' name='Pending' />
            <Bar
              dataKey='withdraw'
              stackId='a'
              fill='#d9c67e'
              name='Withdraw'
            />
            <Bar
              dataKey='received'
              stackId='a'
              fill='#FF7043'
              name='Received'
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Modal
        open={open || showConfirmation}
        onClose={() => {
          handleClose();
          setShowConfirmation(false); // Ensure modal closes completely when hiding confirmation
        }}
        aria-labelledby='withdraw-modal-title'
        aria-describedby='withdraw-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '70%', sm: 400 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          {showConfirmation ? (
            // Confirmation message
            <>
              <CheckCircle sx={{ color: '#4CAF50', fontSize: 60, mb: 2 }} />
              <Typography
                id='confirmation-modal-title'
                variant='h5'
                sx={{ color: '#4CAF50', mb: 1 }}
              >
                Congratulations!
              </Typography>
              <Typography variant='body1' sx={{ color: '#4CAF50' }}>
                Your withdrawal has been completed.
              </Typography>
            </>
          ) : (
            // Withdrawal form
            <>
              <Typography
                id='withdraw-modal-title'
                variant='h6'
                component='h2'
                sx={{ mb: 2 }}
              >
                Withdrawal Details
              </Typography>
              <TextField
                fullWidth
                label='Amount'
                type='number'
                value={withdrawAmount}
                onChange={handleWithdrawAmountChange}
                inputProps={{ max: balance }}
                sx={{ mb: 2 }}
                required
              />
              <Typography variant='body2' sx={{ color: '#757575', mb: 2 }}>
                Total Balance: ${balance.toFixed(2)}
              </Typography>
              <TextField
                fullWidth
                label='Full Name'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <TextField
                fullWidth
                label='Account Number'
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <TextField
                fullWidth
                label='Routing Number'
                value={routingNumber}
                onChange={(e) => setRoutingNumber(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Withdrawal Date'
                  value={withdrawDate}
                  onChange={(newDate) => setWithdrawDate(newDate)}
                  minDate={addMonths(new Date(), 3)} // Earliest date is Jan 7, 2024
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <Typography
                variant='body2'
                sx={{ color: '#757575', mb: 1, mt: 1 }}
              >
                NOTE: Free bank transfers begin January 1st 2025. First
                available payout date is shown above.
              </Typography>
              <Typography variant='body2' sx={{ color: '#757575', mb: 2 }}>
                Please select your payout date and enter your payout information
                to process your withdrawal.
              </Typography>
              {/* Error Message */}
              {errorMessage && (
                <Typography color='error' sx={{ mt: 2 }}>
                  {errorMessage}
                </Typography>
              )}
              <Button
                fullWidth
                variant='contained'
                sx={{
                  mt: 2,
                  backgroundColor: '#d9c67e',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: '#E0C000',
                  },
                }}
                onClick={() => {
                  handleWithdraw();
                  //setShowConfirmation(true); // Show confirmation message after withdraw
                }}
                disabled={balance <= 0}
              >
                Confirm Withdrawal
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Box sx={{ mt: 4, textAlign: 'center', color: '#d9c67e' }}>
        <Typography
          variant='body2'
          sx={{
            fontSize: { xs: '0.7rem', md: '0.9rem' },
            color: '#777',
            mb: 2,
          }}
        >
          WLF Wallet helps you set saving goals, earn cashback offers, and get
          paychecks up to two days early. Visit the disclaimer for more details.
        </Typography>
        <Typography
          variant='body2'
          sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' }, color: '#777' }}
        >
          © 2024 WLF Wallet. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default HomePage;
