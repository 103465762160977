import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem('token');
  
  if (!token) {
    return <Navigate to="/login" />;
  }
  
  const decodedToken = jwtDecode(token);

  // Check if the user role is allowed to access the route
  if (allowedRoles.includes(decodedToken.role)) {
    return children;
  } else {
    return <Navigate to="/home" />; // Redirect if the role is not allowed
  }
};

export default PrivateRoute;
