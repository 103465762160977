import React from 'react';
import { TextField, Box } from '@mui/material';

const SearchBar = ({ searchQuery, setSearchQuery }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <TextField
                label="Search by Name"
                variant="outlined"
                size="small" // Make the TextField smaller
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                    width: '300px', // Set the width to be smaller
                    borderRadius: '20px', // Add rounded corners
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '20px', // Add rounded corners to the input
                    },
                }}
            />
        </Box>
    );
};

export default SearchBar;
