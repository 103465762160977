import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, IconButton, TextField, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const ClientTable = ({ clients, updateClient, deleteClient }) => {
    const [editMode, setEditMode] = useState(null);
    const [editValues, setEditValues] = useState({});

    const handleEdit = (id) => {
        const client = clients.find(client => client._id === id);
        setEditMode(id);
        setEditValues({
            totalAmount: client.totalAmount,
            status: client.status,
        });
    };

    const handleSave = (id) => {
        updateClient(id, editValues);
        setEditMode(null);
    };

    const handleCancel = () => {
        setEditMode(null);
    };

    const handleChange = (field, value) => {
        setEditValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'surname', headerName: 'Surname', width: 150 },
        {
            field: 'totalAmount',
            headerName: 'Total Amount',
            width: 150,
            renderCell: (params) =>
                editMode === params.row.id ? (
                    <TextField
                        value={editValues.totalAmount}
                        onChange={(e) => handleChange('totalAmount', e.target.value)}
                        variant="standard"
                        size="small"
                    />
                ) : (
                    params.value
                ),
        },
        { field: 'username', headerName: 'Username', width: 150 },
        { field: 'pin', headerName: 'PIN', width: 100 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) =>
                editMode === params.row.id ? (
                    <Checkbox
                        checked={editValues.status}
                        onChange={(e) => handleChange('status', e.target.checked)}
                    />
                ) : (
                    <Checkbox checked={params.value} disabled />
                ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    {editMode === params.row.id ? (
                        <>
                            <IconButton onClick={() => handleSave(params.row.id)}>
                                <SaveIcon />
                            </IconButton>
                            <IconButton onClick={handleCancel}>
                                <CancelIcon />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton onClick={() => handleEdit(params.row.id)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteClient(params.row.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </Box>
            ),
        },
    ];

    const rows = clients.map(client => ({
        id: client._id,
        name: client.name,
        surname: client.surname,
        totalAmount: client.totalAmount,
        username: client.username,
        pin: client.pin,
        status: client.status, // Boolean value for checkbox
    }));

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
                Clients
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
            />
        </Box>
    );
};

export default ClientTable;
