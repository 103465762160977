import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/login';
import AdminPanel from './pages/adminPanel';
import HomePage from './pages/homePage';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
const TestComponent = () => <div style={{ color: "black" }}>Test Component</div>;

// In Routes

const App = () => {
    console.log("Routing to Login")
  return (
    <Router>
      <Routes>
        {/* Public Route for Login */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />

        <Route path="/test" element={<TestComponent />} />

        {/* Private Route for Admin (only admins can access this) */}
        <Route 
          path="/admin" 
          element={
            <PrivateRoute allowedRoles={['admin']}>
              <Navbar />
              <AdminPanel />
            </PrivateRoute>
          } 
        />

        {/* Private Route for Home (both admins and users can access this) */}
        <Route 
          path="/home" 
          element={
            <PrivateRoute allowedRoles={['admin', 'user']}>
              <Navbar />
              <HomePage />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
