import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const AddClient = ({ addClient }) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [totalAmount, setTotalAmount] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addClient({ name, surname, totalAmount});
            setName('');
            setSurname('');
            setTotalAmount('')
        } catch (error) {
            console.error('Error adding client:', error);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6">Add user</Typography>
            <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                margin="normal"
            />
            <TextField
                label="Surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
                fullWidth
                margin="normal"
            />
    
            <TextField
                label="Total amount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button type="submit" variant="contained" sx={{ backgroundColor: '#000000', color: '#ffffff' }}>
    Add user
</Button>

        </Box>
    );
};

export default AddClient;
