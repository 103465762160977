import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirecting
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
  const [username, setUsername] = useState('');
  const [pin, setPin] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://wlf.onrender.com/auth/login', {
        username,
        pin,
      });

      // Save the token and user role in localStorage
      localStorage.setItem('token', response.data.token);

      // Decode the token to get user role information
      const decodedToken = jwtDecode(response.data.token);
      console.log(decodedToken);
      // Use jwt-decode library
      localStorage.setItem('role', decodedToken.role);
      const loginTime = Date.now(); // Save current timestamp
      localStorage.setItem('loginTime', loginTime);
      setMessage('Login successful!');

      // Redirect to the appropriate page based on role
      if (decodedToken.role === 'admin') {
        console.log('Redirecting to admin');
        navigate('/admin');
      } else {
        console.log('Redirecting to home');
        navigate('/home');
      }
    } catch (error) {
      setMessage('Login failed. Please check your credentials.');
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#1E1E1E',
        color: '#ffffff',
        padding: { xs: '20px', md: '40px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
          textAlign: 'center',
        }}
      >
        <Box
          component='img'
          src='/logo.png'
          alt='World Liberty Financial Logo'
          sx={{
            width: '120%',
            maxWidth: { xs: '500px', md: '1000px' },
            mb: 2,
            mt: 0, // Reduced top margin
          }}
        />
        <Typography
          variant='h4'
          component='h1'
          gutterBottom
          align='center'
          sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
        >
          Sign in to WFL Wallet
        </Typography>
        <Typography
          variant='body1'
          align='center'
          sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}
        >
          Send, spend and save smarter
        </Typography>
      </Box>

      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', maxWidth: '400px' }}
      >
        <TextField
          fullWidth
          variant='outlined'
          margin='normal'
          label='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{
            style: { color: 'white' }, // Ensures the input text color is white
          }}
          InputLabelProps={{
            style: { color: '#aaa' }, // Light gray color for the label text
          }}
          sx={{
            backgroundColor: '#1E1E1E',
            borderRadius: 1,
            '& .MuiOutlinedInput-root': {
              color: 'white', // Additional check for input text color
              '& fieldset': {
                borderColor: '#444',
              },
              '&:hover fieldset': {
                borderColor: '#777',
              },
            },
            '& .MuiInputBase-input': {
              color: 'white', // Force white color for input text
            },
            fontSize: { xs: '0.9rem', md: '1rem' },
          }}
        />
        <TextField
          fullWidth
          variant='outlined'
          margin='normal'
          label='PIN'
          type='password'
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          InputProps={{
            style: { color: 'white' }, // Ensures the input text color is white
          }}
          InputLabelProps={{
            style: { color: '#aaa' }, // Light gray color for the label text
          }}
          sx={{
            backgroundColor: '#1E1E1E',
            borderRadius: 1,
            '& .MuiOutlinedInput-root': {
              color: 'white', // Additional check for input text color
              '& fieldset': {
                borderColor: '#444',
              },
              '&:hover fieldset': {
                borderColor: '#777',
              },
            },
            '& .MuiInputBase-input': {
              color: 'white', // Force white color for input text
            },
            fontSize: { xs: '0.9rem', md: '1rem' },
          }}
        />

        <FormControlLabel
          control={<Checkbox style={{ color: '#d9c67e' }} />}
          label='Remember me'
          sx={{ color: '#ffffff', fontSize: { xs: '0.9rem', md: '1rem' } }}
        />

        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{
            backgroundColor: '#d9c67e',
            color: '#000000',
            mt: 2,
            py: 1.5,
            fontSize: { xs: '1rem', md: '1.2rem' },
            '&:hover': {
              backgroundColor: '#E0C000',
            },
          }}
        >
          Sign In
        </Button>

        <Typography variant='body2' color='error' align='center' sx={{ mt: 2 }}>
          {message}
        </Typography>
      </form>
      {/* Footer Section */}
      <Box sx={{ mt: 4, textAlign: 'center', color: '#d9c67e' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 1 }}>
          <Typography
            variant='body2'
            sx={{ cursor: 'pointer', fontSize: { xs: '0.8rem', md: '1rem' } }}
          >
            Terms Condition
          </Typography>
          <Typography
            variant='body2'
            sx={{ cursor: 'pointer', fontSize: { xs: '0.8rem', md: '1rem' } }}
          >
            Privacy Policy
          </Typography>
        </Box>

        <Typography
          variant='body2'
          sx={{
            fontSize: { xs: '0.7rem', md: '0.9rem' },
            color: '#777',
            mb: 2,
          }}
        >
          WLF Wallet helps you set saving goals, earn cashback offers, and get
          paychecks up to two days early. Visit the disclaimer for more details.
        </Typography>
        <Typography
          variant='body2'
          sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' }, color: '#777' }}
        >
          © 2024 WLF Wallet. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
